<template>
  <div id="app">
    <v-app>
      <side-nav :drawer="drawer" @update-drawer="updateDrawer"/>
      <top-nav @toggle-drawer="toggleDrawer" />
      <v-main app>
        <v-container fluid class="pb-20">
          <router-view :key="keyForRouterView"></router-view>
        </v-container>
      </v-main>
    <v-snackbar v-model="alert.show" top shaped :timeout="alert.timeout">
      {{alert.text}}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="hideGlobalAlert()" >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import SideNav from '@/components/navigation/side-nav'
import TopNav from '@/components/navigation/top-nav'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'App',
  components: {
    TopNav,
    SideNav
  },
  async mounted() {
    // Attempt to sign in / see if there is an active session
    await this.fetchUser()
  },
  data: () => ({
    drawer: undefined,
  }),
  computed: {
    ...mapGetters({
      alert: 'getGlobalAlert',
    }),
    keyForRouterView() {
      const params = Object.values(this.$route.params)
      return params.join("-")
    }
  },
  methods: {
    ...mapActions({
      setLoggedInUser: 'setLoggedInUser',
      logout: 'logout',
    }),
    toggleDrawer () {
      this.drawer = !this.drawer
    },
    updateDrawer (value) {
      this.drawer = value
    },
    hideGlobalAlert () {
      this.$alert({
        text: undefined,
        show: false
      })
    },
    async fetchUser () {
      try {
        const response = await this.$http.get('/oapi/user')
        if (response.status === 203) {
          // Magic number, user needs to log in
          await this.logout()
        }
        if (response.status === 200) {
          this.setLoggedInUser(response.data)
        }
      } catch (ex) {
        const statusCode = ex.response ? ex.response.status : null
        if (statusCode === 401) {
          console.log('Not logged in, no refresh');
        } else {
          console.error(ex)

        }
      }
    },
  },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*margin-top: 60px;*/
  background-color: #EBEBEBA3;
}

/*this style applies globally*/
.striped-table {
  tbody tr:nth-of-type(odd) {
    background-color: rgb(60, 63, 65, .15);
  }
  table {
    border: 1px solid rgba(0,0,0,.25);
  }
  th {
    border-bottom: thin solid rgba(0,0,0,.62) !important;
  }
}
.scroll-pad {
  scroll-margin-top: 80px;
}
</style>
