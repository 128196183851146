<template>
  <v-form>
    <h2>Test Junk</h2>
<!--    <v-btn class="my-2" @click="callTestApi">Make a Thing Happen</v-btn>-->
<!--    <br />-->
<!--    <v-btn class="my-2" @click="showLeagues">Show Leagues</v-btn>-->
<!--    <br />-->
        <v-btn @click="testAlert">Alert</v-btn>
<!--    <v-btn @click="loginWithGoogle">Log in with Google</v-btn>-->
<!--    {{baseUrl}}-->
<!--    <br>-->
<!--    <a :href="googleOAuthLocation">A Link to Google</a>-->
<!--    <v-btn class="my-2" :href="googleOAuthLocation">A ButtonLink to Google</v-btn>-->
<!--    <br>-->

<!--    <v-btn class="ma-2" @click="fetchUserApi">Fetch User</v-btn>-->
<!--    <v-btn class="ma-2" @click="fetchUserNoAuthApi">Fetch User No Auth Route</v-btn>-->
<!--    <v-btn class="ma-2" @click="fetchUserNoAuthNoCredsApi">Fetch User No Auth No Creds Route</v-btn>-->

  </v-form>
</template>
<script>


/* eslint-disable no-unused-vars */
export default {
  computed: {
    baseUrl () {
      return process.env.VUE_APP_API_BASE_URL
    },
    googleOAuthLocation () {
      return `${this.baseUrl}/oauth2/authorization/google`
    }
  },
  methods: {
    testAlert () {
      this.$alert({
        text: 'This is an alert, yeaaah',
        timeout: 2 * 1000,
        show: true
      })
    },
    async callTestApi () {
      try {
        const response = await this.$http.get('/oapi/test-api')
      } catch (ex) {
        console.error(ex)
      }
    },
    async showLeagues () {
      try {
        const response = await this.$http.get('/oapi/leagues')
      } catch (ex) {
        console.error(ex)
      }
    },
    async fetchUserApi () {
      try {
        const response = await this.$http.get('/user', {
          withCredentials: true
        })
        console.log(JSON.stringify(response))
      } catch (ex) {
        console.error(ex)
      }
    },
    async fetchUserNoAuthApi () {
      try {
        const response = await this.$http.get('/test/user', {
          withCredentials: true
        })
        console.log(JSON.stringify(response))
      } catch (ex) {
        console.error(ex)
      }
    },
    async fetchUserNoAuthNoCredsApi () {
      try {
        // Creds required for this
        const response = await this.$http.get('/test/user')
        console.log(JSON.stringify(response))
      } catch (ex) {
        console.error(ex)
      }
    },
  }
}
</script>
