import axios from 'axios';
const getApiHostname = () => process.env.VUE_APP_API_BASE_URL || ''
import store from '@/store/store'


// Credentials required for Oauth Strategy
const httpClient = axios.create({baseURL: getApiHostname(), withCredentials: true });

const REFRESH_REQUEST_PATH = '/auth/refresh'
const ADDITIONAL_PATHS_TO_NOT_RETRY = ['/open/broker-invite', '/open/invite']

// Required Store State/setters:
// state.auth.isRefreshing
// state.auth.refreshingCall
// SET_AUTH_IS_REFRESHING
// SET_AUTH_REFRESH_CALL

export async function refreshTokenStore(store) {
  if (store.state.auth.isRefreshing) {
    return store.state.auth.refreshingCall
  }
  store.commit('SET_AUTH_IS_REFRESHING', true)
  const refreshingCall = httpClient.post(REFRESH_REQUEST_PATH).then(() => {
    store.commit('SET_AUTH_IS_REFRESHING', false)
    store.commit('SET_AUTH_REFRESH_CALL', undefined)
    return Promise.resolve(true)
  })

  store.commit('SET_AUTH_REFRESH_CALL', refreshingCall)
  return refreshingCall
}

// Auto Retry Interceptor
httpClient.interceptors.response.use(
    response => response,
    error => {
      // Global error handling for 401s
      const statusCode = error.response ? error.response.status : null
      const url = error.response.config.url
      const isSkippedPath = ADDITIONAL_PATHS_TO_NOT_RETRY.some(pathToSkip => {
        return url.startsWith(pathToSkip);
      })
      const isRefreshRequest = url.startsWith(REFRESH_REQUEST_PATH)

      if (isRefreshRequest) {
        // Without this - we can trigger an infinite loop.
        store.dispatch('globalAlert', {
          show: true,
          text: 'Your login has expired.',
        })
        store.dispatch('logout')
        // performLogout() // TODO: do this...
        // router.push({
        //   name: routes.LOGIN_PAGE,
        // })

        return Promise.reject(error)
      }

      // This is a little bit of magic that lets us call /refresh only once,
      // and after the refresh, it retries the original request
      if (statusCode === 401 && !isSkippedPath) {
        // console.log('1. calling refresh ' + error.config.url)
        // User is Unauthorized - likely has an expired cookie for this request.

        const promise = refreshTokenStore(store).then(() => {
          // console.log('2. refresh finished ' + error.config.url)
          // After the refresh is complete, retry the requests
          const errorConfig = error.config
          return httpClient(errorConfig)
        })
        return Promise.resolve(promise)
      } else {
        // console.log('4. Other use case' + error.config.url)
        return Promise.reject(error)
      }
    }
)

function HttpClientPlugin (Vue) {
  Vue.prototype.$http = httpClient;
}

export {
  httpClient as HttpClient,
  getApiHostname,
  HttpClientPlugin,
}
