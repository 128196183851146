import Vue from 'vue'
import VueRouter from 'vue-router'
import PublicLandingPage from '@/views/PublicLandingPage'
import * as r from '@/router/route-names'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: r.HOME,
    component: PublicLandingPage
  },
  {
    path: '/my-user',
    name: r.MY_USER,
    component: () => import('../views/MyUser.vue')
  },
  {
    path: '/rules',
    name: r.RULES,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rules" */ '../views/RulesView.vue')
  },
  {
    path: '/league/:leagueId',
    name: r.LEAGUE,
    component: () => import('../views/LeagueView.vue')
  },
  {
    path: '/league/:leagueId/admin',
    name: r.LEAGUE_ADMIN,
    component: () => import('../views/LeagueAdminView.vue'),
  },
  {
    path: '/league/:leagueId/admin/entry/:entryId',
    name: r.LEAGUE_ADMIN_ENTRY,
    component: () => import('../views/LeagueAdminEntryView.vue'),
  },
  {
    path: '/league/:leagueId/picks',
    name: r.LEAGUE_PICKS,
    component: () => import('../views/LeaguePicksView.vue'),
  },
  {
    path: '/league/:leagueId/entries',
    name: r.LEAGUE_ENTRIES,
    component: () => import('../views/LeagueEntries.vue'),
  },
  {
    path: '/league/:leagueId/hall-of-champions',
    name: r.LEAGUE_CHAMPS,
    component: () => import('../views/LeagueChamps.vue'),
  },
  {
    path: '/global-admin',
    name: r.GLOBAL_ADMIN,
    component: () => import('../views/GlobalAdmin.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
