/* eslint-disable */
import Vuex from 'vuex';
import Vue from 'vue';
import { HttpClient } from '@/components/httpclient/http-client'

Vue.use(Vuex)

const mutations = {
  setUser (state, payload) {
    state.loggedInUser = payload
  },
  setGlobalAlert (state, payload) {
   // Sets defaults
   const alert = {
     show: true,
     timeout: 4000,
     ...payload
   }
   state.alert = alert
  },
  setLeague (state, payload) {
    state.league = payload
  },
  setLeagueAdmin (state, payload) {
    state.league.isAnAdmin = payload
  },
  SET_AUTH_IS_REFRESHING(state, payload) {
    if (payload === undefined || payload === null) {
      throw 'boolean payload - auth isRefreshing required'
    }
    Vue.set(state.auth, 'isRefreshing', payload)
  },
  SET_AUTH_REFRESH_CALL(state, payload) {
    Vue.set(state.auth, 'refreshingCall', payload)
  },
}

const actions = {
  setLoggedInUser (context, payload) {
    context.commit('setUser', payload)
  },
  async logout (context) {
    await HttpClient.post('/auth/logout')

    context.commit('setUser', {
      id: undefined,
      identifier: undefined,
      givenName: undefined,
      familyName: undefined,
    })
    context.commit('setLeagueAdmin', undefined)
  },
  globalAlert (context, payload) {
    context.commit('setGlobalAlert', payload)
  },
  setLeague (context, payload) {
    context.commit('setLeague', payload)
  }
}

const store = new Vuex.Store({
  state: {
    count: 0,
    loggedInUser: {
      id: undefined,
      identifier: undefined,
      givenName: undefined,
      familyName: undefined,
      preferredDisplayName: undefined,
      leagueEntries: {}, // leagueId key { leagueId: [array of entry ids] }
      myLeagues: {}, // leagueId key { league Name }
    },
    alert: {
      show: false,
      text: undefined,
      timeout: 4000,
    },
    league: {
      leagueId: undefined,
      name: undefined,
      isAnAdmin: undefined,
    },
    // Used in refresh retries
    auth: {
      isRefreshing: false,
      refreshingCall: undefined,
    },
  },
  getters: {
    getLoggedInUser (state) {
      return state.loggedInUser
    },
    isLoggedIn (state) {
      return !!state.loggedInUser.id
    },
    getGlobalAlert (state) {
      return state.alert
    },
    getLeague (state) {
      return state.league
    },
    getLeagueId (state) {
      return state.league.leagueId
    },
    isGlobalAdmin (state) {
      return state.loggedInUser && state.loggedInUser.isGlobalAdmin
    },
    getLeagueEntries (state) {
      return state.loggedInUser && state.loggedInUser.leagueEntries
    },
    getUserLeagues (state) {
      return state.loggedInUser && state.loggedInUser.myLeagues || {}
    },
    getUserLeagueIds (state, getters) {
      const leagueIds = Object.keys(getters.getUserLeagues) || []
      return leagueIds
    },
    getNumberOfLeagues (state, getters) {
      const leagueIds = getters.getUserLeagueIds
      return leagueIds.length
    },
  },
  mutations: mutations,
  actions: actions,
})

export default store;
