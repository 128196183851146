export const HOME = 'home'
export const RULES = 'rules'
export const MY_USER = 'my-user'
export const LEAGUE = 'league'
export const LEAGUE_ADMIN = 'league-admin'
export const LEAGUE_ADMIN_ENTRY = 'league-admin-entry'
export const LEAGUE_PICKS = 'league-picks'
export const LEAGUE_ENTRIES = 'league-entries'
export const LEAGUE_CHAMPS = 'league-champs'
export const GLOBAL_ADMIN = 'global-admin'
