import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css'
import router from '@/router'
import store from '@/store/store'
import * as http from '@/components/httpclient/http-client'
import VCalendar from 'v-calendar';

Vue.config.productionTip = false

Vue.use(http.HttpClientPlugin);
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

Vue.prototype.$alert = (payload) => {
  if (typeof payload === 'string' || payload instanceof String) {
    store.dispatch('globalAlert', {
      text: payload
    });
  } else {
    store.dispatch('globalAlert', payload);
  }
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
