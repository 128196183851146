<template>
  <div class="landing-page-outer">
    <div class="landing-page-inner">
      <h1>Bacon Playoff Pickem</h1>
      <template v-if="loading">
        <p>Attempting to Log in... </p>
      </template>
      <template v-if="!loading">
        <template v-if="isLoggedIn">
          <p>Welcome back, {{loggedInUser.preferredDisplayName}} ({{loggedInUser.email}})</p>
        </template>
        <template v-else>
          <p>Sign in to join a league or make your selections</p>
        </template>
      </template>
      <v-divider />
      <div v-if="myLeagues && myLeagues.length > 0">
        <h2 class="mt-2">My Leagues</h2>
        <ul>
          <li class="my-2" v-for="league in myLeagues" :key="league.id">
            <v-btn class="ml-2" @click="viewLeague(league.id)">View</v-btn>
            - {{league.name}}
          </li>
        </ul>
      </div>

      <div class="mt-10">
        <h2 class="mt-2">Leagues</h2>
        <ul>
          <li class="my-2" v-for="league in otherLeagues" :key="league.id">
            <v-btn class="ml-2" @click="viewLeague(league.id)">View</v-btn>
            - {{league.name}}
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import TestApiConnection from '@/components/test/TestApiConnection'
import * as routes from '@/router/route-names'

/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */

export default {
  components: {TestApiConnection},
  async mounted() {
    // Attempt to sign in / see if there is an active session
    await this.showLeagues()
    this.loading = false
  },
  data: () => ({
    loading: true,
    leagues: [],
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      loggedInUser: 'getLoggedInUser',
      getUserLeagueIds: 'getUserLeagueIds',
    }),
    myLeagues() {
      const myLeagues = []
      this.leagues.forEach(l => {
        if (this.getUserLeagueIds.includes(l.id)) {
          myLeagues.push(l)
        }
      })
      return myLeagues
    },
    otherLeagues() {
      const otherLeagues = []
      this.leagues.forEach(l => {
        if (!this.getUserLeagueIds.includes(l.id)) {
          otherLeagues.push(l)
        }
      })
      return otherLeagues
    },
    baseUrl () {
      return process.env.VUE_APP_API_BASE_URL
    },
    googleOAuthLocation () {
      return `${this.baseUrl}/oauth2/authorization/google`
    }
  },
  methods: {
    ...mapActions({
    }),
    async showLeagues () {
      try {
        const response = await this.$http.get('/oapi/leagues')
        if (response.data && response.data.leagues){
          this.leagues = response.data.leagues
        }
      } catch (ex) {
        console.error(ex)
      }
    },
    viewLeague (leagueId) {
      this.$router.push({
        name: routes.LEAGUE,
        params: { leagueId: leagueId}
      }).catch(()=>{});
    },
  }
}
</script>
<style scoped lang="scss">
.landing-page-inner {
  max-width: 800px;
  margin: 0 auto
}
.landing-page-outer {
  max-width: 90%;
  margin: 0 auto
}
</style>
